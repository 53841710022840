import React from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin, faXing } from '@fortawesome/free-brands-svg-icons';

import email from './email.png';
import phone from './phone.png';
import map from './map.png';

class ContactDetails extends React.Component {
	render() {
		const Separator = styled.div`
			height: 5px;
			width: 50px;
			background-color: #04e5e5;
			margin-bottom: 30px;
			margin-left: 6px;
		`;

		const Details = styled.div`
			padding: 40px;
			width: 100%;
			position: relative;
			margin-top: 0;
			z-index: 5;
			display: flex;
			flex-direction: column;
			justify-items: flex-start;
		`;

		const Heading = styled.h2`
			font-size: 3rem;
			font-family: Teko;
			color: #fff;
			@media (max-width: 768px) {
				font-size: 2rem;
			}
		`;

		const ContactHeading = styled.h4`
			font-size: 30px;
			font-weight: 500;
			font-family: Teko;
			color: #fff;
		`;

		const ContactContainer = styled.div`
			display: flex;
			flex-direction: row;
			margin-bottom: 1rem;
		`;
		const Icon = styled.img`
			height: 20px;
			width: 20px;
			object-fit: contain;
			margin-right: 20px;
		`;

		const Text = styled.text`
			line-height: 20px;
			color: white;
			font-size: 1em;
		`;

		const Link = styled.a`
			color: white;
			text-decoration: none;
			:hover {
				color: #04e5e5;
				text-decoration: none;
			}
		`;

		const SocialIcons = styled.div`
			.social_icon {
				font-size: 25px;
				color: #555;
				margin: 0 5px;
				cursor: pointer;
				transition: 0.5s;
				&:hover {
					color: #04e5e5;
				}
			}
		`;

		return (
			<Details>
				<Heading>Ich berate Sie gerne</Heading>
				<Separator />
				<ContactHeading>Alexander Gauss</ContactHeading>
				<ContactContainer>
					<Icon src={email} alt='email' />
					<Text>
						<Link href='mailto:info@alexgauss.de'>info@alexgauss.de</Link>
					</Text>
				</ContactContainer>
				{/* <ContactContainer>
					<Icon src={phone} alt='phone' />
					<Text>+49 (0) 174 477 29 02</Text>
				</ContactContainer> */}
				<ContactContainer>
					<Icon src={map} alt='address' />
					<Text>Magstadt, BW, Germany</Text>
				</ContactContainer>
				<Separator style={{ marginTop: '1rem' }} />
				{/* <SocialIcons>
					<FontAwesomeIcon
						icon={faXing}
						className='social_icon'
						onClick={() => window.open('https://www.xing.com/profile/Alexander_Gauss3')}
					/>
					<FontAwesomeIcon
						icon={faLinkedin}
						className='social_icon'
						onClick={() => window.open('https://www.linkedin.com/in/alexander-g-44275287/')}
					/>
					<FontAwesomeIcon
						icon={faGithub}
						className='social_icon'
						onClick={() => window.open('https://github.com/itsalexg87')}
					/>
				</SocialIcons>
				<Separator style={{ marginTop: '1rem' }} /> */}
				<Text>Ich freue mich darauf Sie kennenzulernen und gemeinsam Ihr Projekt umzusetzen!</Text>
			</Details>
		);
	}
}

export default ContactDetails;
