import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import AnimationContainer from 'components/animation-container';
import AnimatedHeading from 'components/animated-heading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode } from '@fortawesome/free-solid-svg-icons';
import { faDesktop } from '@fortawesome/free-solid-svg-icons';
import { faServer } from '@fortawesome/free-solid-svg-icons';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

class ServicesOne extends React.Component {
	shouldComponentUpdate() {
		return false;
	}

	render() {
		const Section = styled.section`
            position: relative;
            overflow: hidden;
            background-color: #111;
            
            background-size: cover;
            .heading {
                width: 100%;
            }
          }
        `;

		const ServiceContainer = styled.div`
			background-color: rgba(0, 0, 0, 0.9);
			padding: 100px 0;
		`;

		const ServiceElement = styled.div`
			margin-bottom: 20px;
			text-align: center;
			padding: 40px;
			border-radius: 20px;
			transition: 0.2s;
			&:hover {
				background-color: #000;
				box-shadow: 0 28px 60px rgb(4, 229, 229, 0.1);
				transform: translateY(-10px);
			}
		`;
		const ServiceHeading = styled.h4`
			font-size: 30px;
			font-weight: 500;
			font-family: Teko;
			color: #fff;
		`;
		const ServiceSeparator = styled.div`
			height: 5px;
			width: 50px;
			background-color: #04e5e5;
			margin-bottom: 10px;
			margin: auto;
		`;
		const ServiceIcon = styled.div`
			margin-bottom: 20px;
			img {
				max-height: 70px;
			}
		`;

		const ServiceList = styled.ul`
			padding: 0;
			margin: 11px 0 0 0;
		`;

		const ServiceListElement = styled.li`
			list-style: none;
			color: #c5c5c5;
			font-weight: 300;
			font-size: 14px;
			margin: 5px 0px;
			&::before {
				display: inline-block;
				content: '';
				border-radius: 2.5px;
				height: 5px;
				width: 5px;
				margin-right: 10px;
				background-color: #04e5e5;
				position: relative;
				bottom: 2.5px;
			}
		`;

		return (
			<Section id='leistungen'>
				<ServiceContainer>
					<Container>
						<AnimatedHeading text='Meine Leistungen' />
						<Row>
							<Col md={4}>
								<AnimationContainer animation='fadeInLeft' delay={200}>
									<ServiceElement>
										<ServiceIcon>
											{/* <img src={this.props.mobile.childImageSharp.fluid.src} alt='Mobile App Development' /> */}
											<FontAwesomeIcon icon={faDesktop} size='4x' style={{ color: 'white' }} />
										</ServiceIcon>
										<ServiceHeading>Webseite</ServiceHeading>
										<ServiceSeparator />
										<ServiceList>
											<ServiceListElement>Design und Funktionalität</ServiceListElement>
											<ServiceListElement>Optimierung für Mobilgeräte</ServiceListElement>
											<ServiceListElement>Gestaltung, Entwicklung, Relaunch</ServiceListElement>
										</ServiceList>
									</ServiceElement>
								</AnimationContainer>
							</Col>
							<Col md={4}>
								<AnimationContainer animation='fadeInRight' delay={600}>
									<ServiceElement>
										<ServiceIcon>
											<FontAwesomeIcon icon={faCode} size='4x' style={{ color: 'white' }} />
											<span style={{ color: 'black' }}>1</span>
										</ServiceIcon>
										<ServiceHeading>Webapplikation</ServiceHeading>
										<ServiceSeparator />
										<ServiceList>
											<ServiceListElement>Einbindung auf Ihrer Webseite</ServiceListElement>
											<ServiceListElement>Individuelle Entwicklung Ihrer Ideen</ServiceListElement>
											<ServiceListElement>Bspw. Konfigurator, Buchungssystem</ServiceListElement>
										</ServiceList>
									</ServiceElement>
								</AnimationContainer>
							</Col>
							<Col md={4}>
								<AnimationContainer animation='fadeInDown' delay={400}>
									<ServiceElement>
										<ServiceIcon>
											<FontAwesomeIcon icon={faShoppingCart} size='4x' style={{ color: 'white' }} />
										</ServiceIcon>
										<ServiceHeading>Online Shop</ServiceHeading>
										<ServiceSeparator />
										<ServiceList>
											<ServiceListElement>WooCommerce, Shopify</ServiceListElement>
											<ServiceListElement>Einbindung auf Ihrer Webseite</ServiceListElement>
											<ServiceListElement>Einfache Pflege ohne Vorkenntnisse</ServiceListElement>
										</ServiceList>
									</ServiceElement>
								</AnimationContainer>
							</Col>
							<Col md={4}>
								<AnimationContainer animation='fadeInUp' delay={1000}>
									<ServiceElement>
										<ServiceIcon>
											<FontAwesomeIcon icon={faSearch} size='4x' style={{ color: 'white' }} />
										</ServiceIcon>
										<ServiceHeading>SEO</ServiceHeading>
										<ServiceSeparator />
										<ServiceList>
											<ServiceListElement>AdWords Beratung</ServiceListElement>
											<ServiceListElement>Lokale Auffindbarkeit</ServiceListElement>
											<ServiceListElement>Regionale Auffindbarkeit</ServiceListElement>
										</ServiceList>
									</ServiceElement>
								</AnimationContainer>
							</Col>
							<Col md={4}>
								<AnimationContainer animation='fadeInRight' delay={1200}>
									<ServiceElement>
										<ServiceIcon>
											<FontAwesomeIcon icon={faServer} size='4x' style={{ color: 'white' }} />
										</ServiceIcon>
										<ServiceHeading>Hosting</ServiceHeading>
										<ServiceSeparator />
										<ServiceList>
											<ServiceListElement>Hosting Ihrer Webseite</ServiceListElement>
											<ServiceListElement>Hosting Ihres Online-Shops</ServiceListElement>
											<ServiceListElement>Beratung bzgl. Selbsthosting</ServiceListElement>
										</ServiceList>
									</ServiceElement>
								</AnimationContainer>
							</Col>
							<Col md={4}>
								<AnimationContainer animation='fadeInLeft' delay={800}>
									<ServiceElement>
										<ServiceIcon>
											<FontAwesomeIcon icon={faEllipsisH} size='4x' style={{ color: 'white' }} />
										</ServiceIcon>
										<ServiceHeading>Weitere Leistungen</ServiceHeading>
										<ServiceSeparator />
										<ServiceList>
											<ServiceListElement>Individuelle Beratung</ServiceListElement>
											<ServiceListElement>Rechtssicherheit & Cookies</ServiceListElement>
											<ServiceListElement>Erstellung Texte & Graphiken</ServiceListElement>
										</ServiceList>
									</ServiceElement>
								</AnimationContainer>
							</Col>
							{/* <Col md={4}>
								<AnimationContainer animation='fadeInLeft' delay={800}>
									<ServiceElement>
										<ServiceIcon>
											<FontAwesomeIcon icon={faCircle} size='4x' style={{ color: 'white' }}>
												<span>1</span>
											</FontAwesomeIcon>
										</ServiceIcon>
										<ServiceHeading>Circle</ServiceHeading>
										<ServiceSeparator />
										<ServiceList>
											<ServiceListElement>Individuelle Beratung</ServiceListElement>
											<ServiceListElement>Rechtssicherheit & Cookies</ServiceListElement>
											<ServiceListElement>Erstellung Texte & Graphiken</ServiceListElement>
										</ServiceList>
									</ServiceElement>
								</AnimationContainer>
							</Col> */}
						</Row>
					</Container>
				</ServiceContainer>
			</Section>
		);
	}
}

export default props => (
	<StaticQuery
		query={graphql`
			query {
				background: file(relativePath: { eq: "bg2.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 4000, quality: 100) {
							src
						}
					}
				}
				web: file(relativePath: { eq: "icons/web.png" }) {
					childImageSharp {
						fluid(maxWidth: 500) {
							src
						}
					}
				}
				mobile: file(relativePath: { eq: "icons/mobile.png" }) {
					childImageSharp {
						fluid(maxWidth: 500) {
							src
						}
					}
				}
				seo: file(relativePath: { eq: "icons/seo2.png" }) {
					childImageSharp {
						fluid(maxWidth: 500) {
							src
						}
					}
				}
				email: file(relativePath: { eq: "icons/email.png" }) {
					childImageSharp {
						fluid(maxWidth: 500) {
							src
						}
					}
				}
				ui: file(relativePath: { eq: "icons/sketch.png" }) {
					childImageSharp {
						fluid(maxWidth: 500) {
							src
						}
					}
				}
				network: file(relativePath: { eq: "icons/network.png" }) {
					childImageSharp {
						fluid(maxWidth: 500) {
							src
						}
					}
				}
			}
		`}
		render={({ background, web, mobile, seo, email, ui, network }) => (
			<ServicesOne
				background={background}
				web={web}
				mobile={mobile}
				seo={seo}
				email={email}
				ui={ui}
				network={network}
				{...props}
			/>
		)}
	/>
);
