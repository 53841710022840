import React, { useState, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import emailjs from 'emailjs-com';
import Alert from '@material-ui/lab/Alert';
import ReCAPTCHA from 'react-google-recaptcha';

const ContactThreeForm = () => {
	const [emailSent, setEmailSent] = useState(false);
	const recaptchaRef = useRef();

	const Separator = styled.div`
		height: 5px;
		width: 50px;
		background-color: #04e5e5;
		margin-bottom: 30px;
		margin-left: 6px;
	`;

	const ContactForm = styled.div`
		padding: 40px;
		width: 100%;
		position: relative;
		z-index: 5;
	`;

	const Heading = styled.h2`
		font-size: 3rem;
		font-family: Teko;
		color: #fff;
		@media (max-width: 768px) {
			font-size: 2rem;
		}
	`;

	const InputElement = styled.div`
		margin: 20px 0;
	`;

	const ErrorInputAnimation = keyframes`
            0% {
              border-bottom: 1px solid #111;
            }
            100% {
              border-bottom: 1px solid #ff0000;
            }
        `;

	const Input = styled.input`
		width: 100%;
		background-color: #111;
		border: none;
		border-bottom: 1px solid #444;
		padding: 10px 5px;
		border-radius: 0;
		color: #fff;
		transition: 0.5s;
		&:focus {
			border-bottom: 1px solid #04e5e5;
			outline: none;
		}
		&.error {
			animation: ${ErrorInputAnimation} 1s forwards;
		}
	`;

	const Textarea = styled.textarea`
		width: 100%;
		background-color: #111;
		border: none;
		border-bottom: 1px solid #444;
		padding: 10px 5px;
		border-radius: 0;
		color: rgb(255, 255, 255);
		transition: all 0.5s ease 0s;
		min-height: 100px;
		margin-top: 0px;
		margin-bottom: 0px;
		height: 100px;
		&:focus {
			border-bottom: 1px solid #04e5e5;
			outline: none;
		}
	`;

	const Submit = styled.button`
		display: block;
		height: 50px;
		width: 186px;
		position: relative;
		border: none;
		overflow: hidden;
		transition: 0.5s;
		background-color: #000;
		&:hover {
			background: #444;
		}
		span {
			position: relative;
			top: 0;
			color: #fff;
			z-index: 10;
			text-transform: uppercase;
			letter-spacing: 2px;
		}
	`;

	const Form = styled.form`
		width: 100%;
	`;

	const sendEmail = e => {
		e.preventDefault();

		emailjs
			.sendForm('service_7p4jcx6', 'template_qlc3w8o', e.target, 'user_avKSmKDpabna9zdyhP1Wq')
			.then(
				result => {},
				error => {
					console.log(error.text);
				}
			);
		e.target.reset();
		setEmailSent(true);
	};
	return (
		<Form onSubmit={sendEmail}>
			<ContactForm>
				<Heading>Schreiben Sie mir</Heading>
				<Separator />
				<InputElement>
					<Input type='text' name='name' placeholder='Name *' required />
				</InputElement>
				<InputElement>
					<Input type='email' name='email' placeholder='Email *' required />
				</InputElement>
				<InputElement>
					<Input
						type='text'
						name='phone'
						className='phone'
						placeholder='Telefon'
						// required
					/>
				</InputElement>
				<InputElement>
					<Textarea placeholder='Nachricht *' name='message' required />
				</InputElement>
				{/* <ReCAPTCHA
					ref={recaptchaRef}
					size='invisible'
					sitekey='6Lc4j3EaAAAAAKV2Owx4614Qq7gvZ5xM-6Qr-XG-'
				/> */}
				{emailSent ? (
					<Alert severity='success'>Vielen Dank für Ihre Email!</Alert>
				) : (
					<Submit type='submit'>
						<span>Absenden</span>
					</Submit>
				)}
			</ContactForm>
		</Form>
	);
};

export default ContactThreeForm;
