import React, { useState } from 'react';
import styled from 'styled-components';

// MUI
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
// import ListItemText from '@material-ui/core/ListItemText';
// import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

const link = {
	color: 'rgb(4, 229, 229)',
};

const useStyles = makeStyles(theme => ({
	appBar: {
		position: 'relative',
		backgroundColor: '#000000',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	list: {
		backgroundColor: '#111111',
		color: 'white',
		// minHeight: '100vh',
	},
	divider: {
		backgroundColor: 'white!important',
	},
	impressum: {
		backgroundColor: 'grey!important',
		minHeight: '100%',
	},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

const Footer = () => {
	const classes = useStyles();
	const [openImp, setOpenImp] = useState(false);
	const [openDs, setOpenDs] = useState(false);

	const openImpressum = () => {
		setOpenImp(true);
	};

	const closeImpressum = () => {
		setOpenImp(false);
	};

	const openDatenschutz = () => {
		setOpenDs(true);
	};

	const closeDatenschutz = () => {
		setOpenDs(false);
	};

	const FooterMain = styled.div`
		background-color: #111;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
	`;

	const SocialIcons = styled.div`
		.social_icon {
			font-size: 15px;
			color: #555;
			margin: 0 5px;
			cursor: pointer;
			transition: 0.5s;
			&:hover {
				color: #04e5e5;
			}
		}
	`;
	const Text = styled.text`
		line-height: 20px;
		color: #555;
		font-size: 1em;
	`;

	// const Link = styled.a`
	// 	color: white;
	// 	text-decoration: none;
	// 	:hover {
	// 		color: #04e5e5;
	// 		text-decoration: none;
	// 	}
	// `;
	return (
		<FooterMain>
			<SocialIcons>
				<Text>
					{' '}
					&copy; 2022 | alexgauss.de |{' '}
					<Button color='inherit' onClick={openImpressum}>
						Impressum
					</Button>{' '}
					|{' '}
					<Button color='inherit' onClick={openDatenschutz}>
						Datenschutz
					</Button>
				</Text>
				{/* Impressum */}
				<Dialog
					fullScreen
					open={openImp}
					onClose={closeImpressum}
					TransitionComponent={Transition}
					PaperProps={{
						style: {
							backgroundColor: '#111111',
							boxShadow: 'none',
						},
					}}>
					<AppBar className={classes.appBar}>
						<Toolbar>
							<IconButton edge='start' color='inherit' onClick={closeImpressum} aria-label='close'>
								<CloseIcon />
							</IconButton>
							<Typography variant='h6' className={classes.title}>
								Impressum
							</Typography>
						</Toolbar>
					</AppBar>
					<List className={classes.list}>
						<div style={{ margin: '50px 50px 50px 50px' }}>
							<Typography variant='body1'>
								Alexander Gauß
								<br />
								Tulpenweg 16 <br />
								71106 Magstadt, Deutschland
								<br />
								<br />
								<Divider />
								<br />
								<strong>E-Mail-Adresse:</strong> info@alexgauss.de <br />
								{/* <strong>Telefonnummer:</strong> +49 (0) 174 477 29 02 */}
								<br />
								<br />
								<Divider />
								<br />
								<strong>Umsatzsteuer-Identifikationsnummer:</strong> DE349639683
								<br />
								<br />
								<Divider />
								<br />
								<strong>Verantwortlich für den Inhalt:</strong> Alexander Gauß
								<br />
								<br />
								<Divider />
								<br />
								Plattform der Europäischen Kommission zur Online-Streitbeilegung (OS) für Verbraucher:{' '}
								<a style={link} href='https://ec.europa.eu/consumers/odr/' target='_blank' rel='noopener'>
									https://ec.europa.eu/consumers/odr/
								</a>
								.
								<br />
								Ich bin nicht bereit und nicht verpflichtet an einem Streitbeilegungsverfahren vor einer
								Verbraucherschlichtungsstelle teilzunehmen.
							</Typography>
						</div>
					</List>
				</Dialog>

				{/* Datenschutz */}
				<Dialog
					fullScreen
					open={openDs}
					onClose={closeDatenschutz}
					TransitionComponent={Transition}
					className={classes.datenSchutz}>
					<AppBar className={classes.appBar}>
						<Toolbar>
							<IconButton edge='start' color='inherit' onClick={closeDatenschutz} aria-label='close'>
								<CloseIcon />
							</IconButton>
							<Typography variant='h6' className={classes.title}>
								Datenschutzerklärung
							</Typography>
						</Toolbar>
					</AppBar>
					<List className={classes.list}>
						<div style={{ margin: '50px 50px 50px 50px' }}>
							<Typography variant='h5'>1. Datenschutz auf einen Blick</Typography>
							<Typography variant='h6'>Allgemeine Hinweise</Typography>{' '}
							<p>
								Die folgenden Hinweise geben einen einfachen &Uuml;berblick dar&uuml;ber, was mit Ihren
								personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten
								sind alle Daten, mit denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen.
								Ausf&uuml;hrliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem
								Text aufgef&uuml;hrten Datenschutzerkl&auml;rung.
							</p>
							<Typography variant='h5'>Datenerfassung auf dieser Website</Typography>{' '}
							<Typography variant='body1'>
								<strong>Wer ist verantwortlich f&uuml;r die Datenerfassung auf dieser Website?</strong>
							</Typography>
							<p>
								Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen
								Kontaktdaten k&ouml;nnen Sie dem Abschnitt &bdquo;Hinweis zur Verantwortlichen Stelle&ldquo;
								in dieser Datenschutzerkl&auml;rung entnehmen.
							</p>
							<Typography variant='body1'>
								<strong>Wie erfassen wir Ihre Daten?</strong>
							</Typography>
							<p>
								Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es
								sich z.&nbsp;B. um Daten handeln, die Sie in ein Kontaktformular eingeben.
							</p>
							<p>
								Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch
								unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z.&nbsp;B. Internetbrowser,
								Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
								automatisch, sobald Sie diese Website betreten.
							</p>
							<Typography variant='body1'>
								<strong>Wof&uuml;r nutzen wir Ihre Daten?</strong>
							</Typography>
							<p>
								Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu
								gew&auml;hrleisten. Andere Daten k&ouml;nnen zur Analyse Ihres Nutzerverhaltens verwendet
								werden.
							</p>
							<Typography variant='body1'>
								<strong>Welche Rechte haben Sie bez&uuml;glich Ihrer Daten?</strong>
							</Typography>
							<p>
								Sie haben jederzeit das Recht, unentgeltlich Auskunft &uuml;ber Herkunft, Empf&auml;nger und
								Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben au&szlig;erdem ein
								Recht, die Berichtigung oder L&ouml;schung dieser Daten zu verlangen. Wenn Sie eine
								Einwilligung zur Datenverarbeitung erteilt haben, k&ouml;nnen Sie diese Einwilligung
								jederzeit f&uuml;r die Zukunft widerrufen. Au&szlig;erdem haben Sie das Recht, unter
								bestimmten Umst&auml;nden die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen
								Daten zu verlangen. Des Weiteren steht Ihnen ein Beschwerderecht bei der zust&auml;ndigen
								Aufsichtsbeh&ouml;rde zu.
							</p>
							<p>
								Hierzu sowie zu weiteren Fragen zum Thema Datenschutz k&ouml;nnen Sie sich jederzeit an uns
								wenden.
							</p>
							<Typography variant='h5'>2. Hosting und Content Delivery Networks (CDN)</Typography>
							<Typography variant='h6'>Externes Hosting</Typography>
							<p>
								Diese Website wird bei einem externen Dienstleister gehostet (Hoster). Die personenbezogenen
								Daten, die auf dieser Website erfasst werden, werden auf den Servern des Hosters
								gespeichert. Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen, Meta- und
								Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige Daten,
								die &uuml;ber eine Website generiert werden, handeln.
							</p>{' '}
							<p>
								Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserf&uuml;llung gegen&uuml;ber unseren
								potenziellen und bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer
								sicheren, schnellen und effizienten Bereitstellung unseres Online-Angebots durch einen
								professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
							</p>{' '}
							<p>
								Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur Erf&uuml;llung seiner
								Leistungspflichten erforderlich ist und unsere Weisungen in Bezug auf diese Daten befolgen.
							</p>{' '}
							<p>Wir setzen folgenden Hoster ein:</p>
							<p>Google Firebase, Google LLC, Amphitheatre Parkway, Mountain View, CA 94043, USA.</p>
							<Typography variant='h6'>
								Abschluss eines Vertrages &uuml;ber Auftragsverarbeitung
							</Typography>
							<p>
								Um die datenschutzkonforme Verarbeitung zu gew&auml;hrleisten, haben wir einen Vertrag
								&uuml;ber Auftragsverarbeitung mit unserem Hoster geschlossen.
							</p>
							<Typography variant='h5'>3. Allgemeine Hinweise und Pflicht&shy;informationen</Typography>
							<Typography variant='h6'>Datenschutz</Typography>{' '}
							<p>
								Die Betreiber dieser Seiten nehmen den Schutz Ihrer pers&ouml;nlichen Daten sehr ernst. Wir
								behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen
								Datenschutzvorschriften sowie dieser Datenschutzerkl&auml;rung.
							</p>{' '}
							<p>
								Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben.
								Personenbezogene Daten sind Daten, mit denen Sie pers&ouml;nlich identifiziert werden
								k&ouml;nnen. Die vorliegende Datenschutzerkl&auml;rung erl&auml;utert, welche Daten wir
								erheben und wof&uuml;r wir sie nutzen. Sie erl&auml;utert auch, wie und zu welchem Zweck das
								geschieht.
							</p>{' '}
							<p>
								Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet (z.&nbsp;B. bei der
								Kommunikation per E-Mail) Sicherheitsl&uuml;cken aufweisen kann. Ein l&uuml;ckenloser Schutz
								der Daten vor dem Zugriff durch Dritte ist nicht m&ouml;glich.
							</p>
							<Typography variant='h6'>Hinweis zur verantwortlichen Stelle</Typography>
							<p>Die verantwortliche Stelle f&uuml;r die Datenverarbeitung auf dieser Website ist:</p>
							<p>
								Alexander Gau&szlig;
								<br />
								Tulpenweg 16 <br />
								71106 Magstadt
							</p>
							<p>E-Mail: info@alexgauss.de</p>
							<p>
								Verantwortliche Stelle ist die nat&uuml;rliche oder juristische Person, die allein oder
								gemeinsam mit anderen &uuml;ber die Zwecke und Mittel der Verarbeitung von personenbezogenen
								Daten (z.&nbsp;B. Namen, E-Mail-Adressen o. &Auml;.) entscheidet.
							</p>
							<Typography variant='h6'>Speicherdauer</Typography>
							<p>
								Soweit innerhalb dieser Datenschutzerkl&auml;rung keine speziellere Speicherdauer genannt
								wurde, verbleiben Ihre personenbezogenen Daten bei uns, bis der Zweck f&uuml;r die
								Datenverarbeitung entf&auml;llt. Wenn Sie ein berechtigtes L&ouml;schersuchen geltend machen
								oder eine Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten gel&ouml;scht,
								sofern wir keine anderen rechtlich zul&auml;ssigen&nbsp; Gr&uuml;nde f&uuml;r die
								Speicherung Ihrer personenbezogenen Daten haben (z.B. steuer- oder handelsrechtliche
								Aufbewahrungsfristen); im letztgenannten Fall erfolgt die L&ouml;schung nach Fortfall dieser
								Gr&uuml;nde.
							</p>
							<Typography variant='h6'>Hinweis zur Datenweitergabe in die USA</Typography>
							<p>
								Auf unserer Website sind unter anderem Tools von Unternehmen mit Sitz in den USA
								eingebunden. Wenn diese Tools aktiv sind, k&ouml;nnen Ihre personenbezogenen Daten an die
								US-Server der jeweiligen Unternehmen weitergegeben werden. Wir weisen darauf hin, dass die
								USA kein sicherer Drittstaat im Sinne des EU-Datenschutzrechts sind. US-Unternehmen sind
								dazu verpflichtet, personenbezogene Daten an Sicherheitsbeh&ouml;rden herauszugeben, ohne
								dass Sie als Betroffener hiergegen gerichtlich vorgehen k&ouml;nnten. Es kann daher nicht
								ausgeschlossen werden, dass US-Beh&ouml;rden (z.B. Geheimdienste) Ihre auf US-Servern
								befindlichen Daten zu &Uuml;berwachungszwecken verarbeiten, auswerten und dauerhaft
								speichern. Wir haben auf diese Verarbeitungst&auml;tigkeiten keinen Einfluss.
							</p>
							<Typography variant='h6'>Widerruf Ihrer Einwilligung zur Datenverarbeitung</Typography>
							<p>
								Viele Datenverarbeitungsvorg&auml;nge sind nur mit Ihrer ausdr&uuml;cklichen Einwilligung
								m&ouml;glich. Sie k&ouml;nnen eine bereits erteilte Einwilligung jederzeit widerrufen. Die
								Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom
								Widerruf unber&uuml;hrt.
							</p>
							<Typography variant='h6'>
								Widerspruchsrecht gegen die Datenerhebung in besonderen F&auml;llen sowie gegen
								Direktwerbung (Art. 21 DSGVO)
							</Typography>{' '}
							<p>
								WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT,
								HABEN SIE JEDERZEIT DAS RECHT, AUS GR&Uuml;NDEN, DIE SICH AUS IHRER BESONDEREN SITUATION
								ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES
								GILT AUCH F&Uuml;R EIN AUF DIESE BESTIMMUNGEN GEST&Uuml;TZTES PROFILING. DIE JEWEILIGE
								RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER
								DATENSCHUTZERKL&Auml;RUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN
								PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR K&Ouml;NNEN ZWINGENDE
								SCHUTZW&Uuml;RDIGE GR&Uuml;NDE F&Uuml;R DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN,
								RECHTE UND FREIHEITEN &Uuml;BERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG,
								AUS&Uuml;BUNG ODER VERTEIDIGUNG VON RECHTSANSPR&Uuml;CHEN (WIDERSPRUCH NACH ART. 21 ABS. 1
								DSGVO).
							</p>{' '}
							<p>
								WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE
								DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER
								DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH F&Uuml;R DAS PROFILING,
								SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE
								PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET
								(WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).
							</p>
							<Typography variant='h6'>
								Beschwerde&shy;recht bei der zust&auml;ndigen Aufsichts&shy;beh&ouml;rde
							</Typography>
							<p>
								Im Falle von Verst&ouml;&szlig;en gegen die DSGVO steht den Betroffenen ein Beschwerderecht
								bei einer Aufsichtsbeh&ouml;rde, insbesondere in dem Mitgliedstaat ihres gew&ouml;hnlichen
								Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutma&szlig;lichen Versto&szlig;es zu.
								Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
								gerichtlicher Rechtsbehelfe.
							</p>
							<Typography variant='h6'>Recht auf Daten&shy;&uuml;bertrag&shy;barkeit</Typography>
							<p>
								Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erf&uuml;llung
								eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem
								g&auml;ngigen, maschinenlesbaren Format aush&auml;ndigen zu lassen. Sofern Sie die direkte
								&Uuml;bertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
								soweit es technisch machbar ist.
							</p>
							<Typography variant='h6'>SSL- bzw. TLS-Verschl&uuml;sselung</Typography>
							<p>
								Diese Seite nutzt aus Sicherheitsgr&uuml;nden und zum Schutz der &Uuml;bertragung
								vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als
								Seitenbetreiber senden, eine SSL- bzw. TLS-Verschl&uuml;sselung. Eine verschl&uuml;sselte
								Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von &bdquo;http://&ldquo;
								auf &bdquo;https://&ldquo; wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
							</p>{' '}
							<p>
								Wenn die SSL- bzw. TLS-Verschl&uuml;sselung aktiviert ist, k&ouml;nnen die Daten, die Sie an
								uns &uuml;bermitteln, nicht von Dritten mitgelesen werden.
							</p>
							<Typography variant='h6'>Auskunft, L&ouml;schung und Berichtigung</Typography>
							<p>
								Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf
								unentgeltliche Auskunft &uuml;ber Ihre gespeicherten personenbezogenen Daten, deren Herkunft
								und Empf&auml;nger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung
								oder L&ouml;schung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene
								Daten k&ouml;nnen Sie sich jederzeit an uns wenden.
							</p>
							<Typography variant='h6'>Recht auf Einschr&auml;nkung der Verarbeitung</Typography>{' '}
							<p>
								Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten
								zu verlangen. Hierzu k&ouml;nnen Sie sich jederzeit an uns wenden. Das Recht auf
								Einschr&auml;nkung der Verarbeitung besteht in folgenden F&auml;llen:
							</p>{' '}
							<ul>
								{' '}
								<li>
									Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten,
									ben&ouml;tigen wir in der Regel Zeit, um dies zu &uuml;berpr&uuml;fen. F&uuml;r die Dauer
									der Pr&uuml;fung haben Sie das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer
									personenbezogenen Daten zu verlangen.
								</li>{' '}
								<li>
									Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtm&auml;&szlig;ig
									geschah/geschieht, k&ouml;nnen Sie statt der L&ouml;schung die Einschr&auml;nkung der
									Datenverarbeitung verlangen.
								</li>{' '}
								<li>
									Wenn wir Ihre personenbezogenen Daten nicht mehr ben&ouml;tigen, Sie sie jedoch zur
									Aus&uuml;bung, Verteidigung oder Geltendmachung von Rechtsanspr&uuml;chen ben&ouml;tigen,
									haben Sie das Recht, statt der L&ouml;schung die Einschr&auml;nkung der Verarbeitung Ihrer
									personenbezogenen Daten zu verlangen.
								</li>{' '}
								<li>
									Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine
									Abw&auml;gung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht
									feststeht, wessen Interessen &uuml;berwiegen, haben Sie das Recht, die Einschr&auml;nkung
									der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
								</li>{' '}
							</ul>{' '}
							<p>
								Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschr&auml;nkt haben,
								d&uuml;rfen diese Daten &ndash; von ihrer Speicherung abgesehen &ndash; nur mit Ihrer
								Einwilligung oder zur Geltendmachung, Aus&uuml;bung oder Verteidigung von
								Rechtsanspr&uuml;chen oder zum Schutz der Rechte einer anderen nat&uuml;rlichen oder
								juristischen Person oder aus Gr&uuml;nden eines wichtigen &ouml;ffentlichen Interesses der
								Europ&auml;ischen Union oder eines Mitgliedstaats verarbeitet werden.
							</p>
							<Typography variant='h5'>4. Datenerfassung auf dieser Website</Typography>
							<Typography variant='h6'>Kontaktformular</Typography>
							<p>
								Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem
								Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der
								Anfrage und f&uuml;r den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
								nicht ohne Ihre Einwilligung weiter.
							</p>{' '}
							<p>
								Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern
								Ihre Anfrage mit der Erf&uuml;llung eines Vertrags zusammenh&auml;ngt oder zur
								Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen erforderlich ist. In allen &uuml;brigen
								F&auml;llen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven
								Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
								Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.
							</p>{' '}
							<p>
								Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur
								L&ouml;schung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck
								f&uuml;r die Datenspeicherung entf&auml;llt (z.&nbsp;B. nach abgeschlossener Bearbeitung
								Ihrer Anfrage). Zwingende gesetzliche Bestimmungen &ndash; insbesondere Aufbewahrungsfristen
								&ndash; bleiben unber&uuml;hrt.
							</p>
							<Typography variant='h6'>Anfrage per E-Mail, Telefon oder Telefax</Typography>
							<p>
								Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive
								aller daraus hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der
								Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht
								ohne Ihre Einwilligung weiter.
							</p>{' '}
							<p>
								Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern
								Ihre Anfrage mit der Erf&uuml;llung eines Vertrags zusammenh&auml;ngt oder zur
								Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen erforderlich ist. In allen &uuml;brigen
								F&auml;llen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven
								Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
								Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.
							</p>{' '}
							<p>
								Die von Ihnen an uns per Kontaktanfragen &uuml;bersandten Daten verbleiben bei uns, bis Sie
								uns zur L&ouml;schung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der
								Zweck f&uuml;r die Datenspeicherung entf&auml;llt (z.&nbsp;B. nach abgeschlossener
								Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen &ndash; insbesondere
								gesetzliche Aufbewahrungsfristen &ndash; bleiben unber&uuml;hrt.
							</p>
							<Typography variant='h5'>5. Plugins und Tools</Typography>
							<Typography variant='h6'>Google Web Fonts</Typography>
							<p>
								Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die
								von Google bereitgestellt werden. Beim Aufruf einer Seite l&auml;dt Ihr Browser die
								ben&ouml;tigten Web Fonts in ihren Browsercache, um Texte und Schriftarten korrekt
								anzuzeigen.
							</p>{' '}
							<p>
								Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von Google
								aufnehmen. Hierdurch erlangt Google Kenntnis dar&uuml;ber, dass &uuml;ber Ihre IP-Adresse
								diese Website aufgerufen wurde. Die Nutzung von Google WebFonts erfolgt auf Grundlage von
								Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der
								einheitlichen Darstellung des Schriftbildes auf seiner Website. Sofern eine entsprechende
								Einwilligung abgefragt wurde (z. B. eine Einwilligung zur Speicherung von Cookies), erfolgt
								die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
								Einwilligung ist jederzeit widerrufbar.
							</p>{' '}
							<p>
								Wenn Ihr Browser Web Fonts nicht unterst&uuml;tzt, wird eine Standardschrift von Ihrem
								Computer genutzt.
							</p>{' '}
							<p>
								Weitere Informationen zu Google Web Fonts finden Sie unter{' '}
								<a
									href='https://developers.google.com/fonts/faq'
									target='_blank'
									rel='noopener noreferrer'
									style={link}>
									https://developers.google.com/fonts/faq
								</a>{' '}
								und in der Datenschutzerkl&auml;rung von Google:{' '}
								<a
									style={link}
									href='https://policies.google.com/privacy?hl=de'
									target='_blank'
									rel='noopener noreferrer'>
									https://policies.google.com/privacy?hl=de
								</a>
								.
							</p>
							<Typography variant='h6'>Font Awesome</Typography>
							<p>
								Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten und Symbolen Font Awesome.
								Anbieter ist die Fonticons, Inc., 6 Porter Road Apartment 3R, Cambridge, Massachusetts, USA.
							</p>{' '}
							<p>
								Beim Aufruf einer Seite l&auml;dt Ihr Browser die ben&ouml;tigten Fonts in ihren
								Browsercache, um Texte, Schriftarten und Symbole korrekt anzuzeigen. Zu diesem Zweck muss
								der von Ihnen verwendete Browser Verbindung zu den Servern von Font Awesome aufnehmen.
								Hierdurch erlangt Font Awesome Kenntnis dar&uuml;ber, dass &uuml;ber Ihre IP-Adresse diese
								Website aufgerufen wurde. Die Nutzung von Font Awesome erfolgt auf Grundlage von Art. 6 Abs.
								1 lit. f DSGVO. Wir haben ein berechtigtes Interesse an der einheitlichen Darstellung des
								Schriftbildes auf unserer Website. Sofern eine entsprechende Einwilligung abgefragt wurde
								(z. B. eine Einwilligung zur Speicherung von Cookies), erfolgt die Verarbeitung
								ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist
								jederzeit widerrufbar.
							</p>{' '}
							<p>
								Wenn Ihr Browser Font Awesome nicht unterst&uuml;tzt, wird eine Standardschrift von Ihrem
								Computer genutzt.
							</p>{' '}
							<p>
								Weitere Informationen zu Font Awesome finden Sie&nbsp;und in der Datenschutzerkl&auml;rung
								von Font Awesome unter:{' '}
								<a
									href='https://fontawesome.com/privacy'
									style={link}
									target='_blank'
									rel='noopener noreferrer'>
									https://fontawesome.com/privacy
								</a>
								.
							</p>
							<p>
								Quelle:{' '}
								<a style={link} target='_blank' rel='noopener' href='https://www.e-recht24.de'>
									e-recht24.de
								</a>
							</p>
						</div>
					</List>
				</Dialog>
			</SocialIcons>
		</FooterMain>
	);
};

export default Footer;
