import React from 'react';
import styled, { keyframes } from 'styled-components';
import Progress from 'components/progress';
import Timeline from 'sections/about/parts/Timeline';

class TabsPart extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			tab: 'skills',
		};
	}

	render() {
		const TabContainer = styled.div`
			min-height: 400px;
			margin-top: 20px;
			@media (max-width: 767px) {
				margin-top: 50px;
				padding: 0 20px;
			}
		`;
		const TabSelectors = styled.div`
			display: flex;
		`;

		const ColorAnimation = keyframes`
            0%  {border-color: #04e5e5;}
            10% {border-color: #f37055;}
            20% {border-color: #ef4e7b;}
            30% {border-color: #a166ab;}
            40% {border-color: #5073b8;}
            50% {border-color: #04e5e5;}
            60% {border-color: #07b39b;}
            70% {border-color: #6fba82;}
            80% {border-color: #5073b8;}
            90% {border-color: #1098ad;}
            100% {border-color: #f37055;}
        `;
		const TabSelector = styled.button`
			color: #fff;
			font-size: 20px;
			font-weight: bold;
			background-color: transparent;
			border: none;
			margin: 0 10px 0 0;
			border-bottom: 2px solid #fff;
			transition: 0.5s;
			&:hover,
			&.active {
				animation: ${ColorAnimation} 10s infinite alternate;
			}
			&:focus {
				outline: none;
			}
			@media (max-width: 767px) {
				font-size: 18px;
			}
		`;

		const Tabs = styled.div`
			margin-top: 20px;
		`;

		const Fade = keyframes`
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        `;

		const Tab = styled.div`
			display: flex;
			animation: ${Fade} 1s forwards;
		`;

		return (
			<TabContainer>
				<TabSelectors>
					<TabSelector
						className={this.state.tab === 'skills' ? 'active' : ''}
						onClick={() => this.setState({ tab: 'skills' })}>
						Skills
					</TabSelector>
					{/* <TabSelector
						className={this.state.tab === 'experience' ? 'active' : ''}
						onClick={() => this.setState({ tab: 'experience' })}>
						Erfahrung
					</TabSelector>
					<TabSelector
						className={this.state.tab === 'education' ? 'active' : ''}
						onClick={() => this.setState({ tab: 'education' })}>
						Ausbildung
					</TabSelector> */}
				</TabSelectors>
				<Tabs>
					<Tab
						style={{
							display: this.state.tab === 'skills' ? 'block' : 'none',
						}}>
						<Progress value={90} text='HTML/CSS' />
						<Progress value={90} text='JavaScript (React, Node)' />
						<Progress value={80} text='Wordpress / WooCommerce' />
						<Progress value={80} text='Shopify' />
						<Progress value={70} text='SEO' />
					</Tab>
				</Tabs>
				<Tabs>
					<Tab
						style={{
							display: this.state.tab === 'experience' ? 'block' : 'none',
						}}>
						<Timeline
							data={{
								'2020 - jetzt': {
									title: 'Freelancer Webentwicklung',
									institution: 'AlexGauss',
									description:
										'Realisierung von Projekten für Kunden im Bereich Web- bzw. App-Entwicklung. Darüber hinaus Umsetzung eigener Projekte.',
								},
								'2017 - jetzt': {
									title: 'CEO',
									institution: 'Adwega',
									description:
										'Personalvermitllung, Mitarbeiterführung, Consulting, Web- und Andwendungsentwicklung.',
								},
								'2016 - 2017': {
									title: 'Personalberater IT',
									institution: 'Personalvermittlung',
									description: '',
								},
								'2015 - 2016': {
									title: 'Consultant Financial Services',
									institution: 'PwC WPG AG',
									description: '',
								},
								'2010 - 2012': {
									title: 'Kundenberater',
									institution: 'Kreissparkasse',
									description: '',
								},
							}}
						/>
					</Tab>
					<Tab
						style={{
							display: this.state.tab === 'education' ? 'block' : 'none',
						}}>
						<Timeline
							data={{
								'2018 - jetzt': {
									title: 'Selbststudium Webentwicklung',
									institution: 'Verschiedene Quellen',
									description:
										'Hauptfokus auf Webentwicklung. Front- sowie Backend. Umsetzung von Websiten, Webapplikationen und Mobile Apps. Technologien: JavaScript, HTML, CSS, React, Angular, Firebase.',
								},
								'2012 - 2015': {
									title: 'B.Sc. internationale technische Betriebswirtschaft',
									institution: 'HS Esslingen',
									description:
										'Unternehmenszusammenhänge, Controlling, Marketing. U.a. Auslandssemester in Brasilien. Bachelorarbeit bei Daimler im Bereich internationales Projektmanagement. ',
								},
								'2010 - 2011': {
									title: 'Bankfachwirt (SBW)',
									institution: 'Sparkassenakademie',
									description: '',
								},
								'2008 - 2010': {
									title: 'Finanzassistent',
									institution: 'Kreissparkasse',
									description: '',
								},
								'1997 - 2007': {
									title: 'Abitur',
									institution: 'Gymnasium',
									description: '',
								},
							}}
						/>
					</Tab>
				</Tabs>
			</TabContainer>
		);
	}
}

export default TabsPart;
