import React from 'react';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
// import { Switch } from '@material-ui/core';
// import ReactCountryFlag from 'react-country-flag';
var scrollToElement = require('scroll-to-element');

class Navbar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			collapse: false,
			sticky: false,
			sections: this.props.sections
				? this.props.sections
				: [
						'home',
						'about',
						'services',
						'portfolio',
						'testimonials',
						'clients',
						'team',
						'blog',
						'contact',
				  ],
		};
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll, { passive: true });
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (window.pageYOffset >= 50 && this.state.sticky) {
			if (this.state.collapse !== nextState.collapse) {
				return true;
			}
			return false;
		} else {
			return true;
		}
	}

	handleScroll = event => {
		if (window.pageYOffset >= 50) {
			this.setState({ sticky: true });
		} else {
			this.setState({ sticky: false });
		}
	};

	collapseNav() {
		console.log(this.state, 'col');
		if (!this.state.collapse) {
			this.setState({ collapse: true });
		} else {
			this.setState({ collapse: false });
		}
	}

	render() {
		const NavbarWrapper = styled.div`
			position: absolute;
			z-index: 1;
			width: 100%;
			padding: 20px 0;
			z-index: 100;
			&.sticky {
				position: fixed;
				background-color: rgba(0, 0, 0, 0.8);
				padding: 0 0;
				@media (max-width: 500px) {
					padding: 20px 0;
				}
			}
		`;

		const NavbarContainer = styled(Container)`
			display: flex;
			position: relative;
			@media (max-width: 500px) {
				display: block;
				padding: 0;
			}
		`;

		const Nav = styled.nav`
			flex: 0 0 80%;
			max-width: 80%;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			@media (max-width: 500px) {
				flex: 0 0 100%;
				max-width: 100%;
				justify-content: center;
				background-color: rgba(0, 0, 0, 0.8);
				margin-top: 20px;
				&.hidden_mobile {
					display: none;
				}
			}
		`;

		const LogoWrapper = styled.div`
			flex: 0 0 20%;
			max-width: 20%;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			@media (max-width: 500px) {
				flex: 0 0 100%;
				max-width: 100%;
				justify-content: center;
			}
		`;

		const Logo = styled.img`
			height: 40px;
			@media (max-width: 1023px) {
				height: 30px;
			}
		`;

		const Text = styled.span`
			font-family: Poppins;
			font-weight: 700;
			font-size: 1.7em;
			color: white;
			@media (max-width: 1023px) {
				font-size: 1.2em;
			}
		`;

		const NavInner = styled.div`
			justify-content: flex-end;
		`;

		const Toggler = styled.button`
			color: #fff;
			position: absolute;
			right: 0;
			top: 0;
			@media (min-width: 500px) {
				display: none;
			}
		`;

		return (
			<NavbarWrapper className={`header${this.state.sticky === true ? ' sticky' : ''}`}>
				<NavbarContainer>
					<LogoWrapper className='logo'>
						<Text>AlexGauss</Text>
						{/* <Logo src='/img/logo.png' alt='logo' /> */}
					</LogoWrapper>
					<Toggler onClick={() => this.collapseNav()} className='navbar-toggler navbar-toggler-right'>
						<FontAwesomeIcon icon={faBars} className='bars' />
					</Toggler>
					<Nav
						className={`navbar navbar-expand-sm ${
							this.state.collapse === true ? 'expand' : 'hidden_mobile'
						}`}>
						<NavInner
							className={`navbar-collapse collapse ${this.state.collapse === true ? 'show' : ''}`}>
							<div className='navbar-nav'>{this.navItems()}</div>
						</NavInner>
						{/* <Switch
							onChange={() => console.log('changed')}
							icon={
								<ReactCountryFlag
									countryCode='DE'
									svg
									style={{ borderRadius: '50%', objectFit: 'cover', border: '1px solid white' }}
								/>
							}
							checkedIcon={
								<ReactCountryFlag
									countryCode='US'
									svg
									style={{ borderRadius: '50%', objectFit: 'cover', border: '1px solid white' }}
								/>
							}
						/> */}
					</Nav>
				</NavbarContainer>
			</NavbarWrapper>
		);
	}

	navigate(id) {
		if (this.props.scroll) {
			const el = document.getElementById(id);
			scrollToElement(el, {
				offset: 0,
				ease: 'in-out-expo',
				duration: 2000,
			});
		} else {
			window.location.href = `./#${id}`;
		}
	}

	navItems() {
		const NavItem = styled.button`
			background: none;
			border: none;
			color: #fff;
			text-transform: capitalize;
			font-weight: 500;
			margin: 10px 5px;
			transition: 0.5s;
			&:hover {
				color: #04e5e5;
			}
			&:focus {
				outline: none;
			}
			@media (min-width: 501px) and (max-width: 1023px) {
				font-size: 11px;
				margin: 2px;
			}
		`;

		return this.state.sections.map((value, index) => {
			return (
				<NavItem key={index} onClick={() => this.navigate(value)}>
					{value}
				</NavItem>
			);
		});
	}
}

export default Navbar;
