import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from 'components/layout';
import HeroPersonal from 'sections/hero/HeroPersonal.js';
import AboutPersonal from 'sections/about/AboutPersonal.js';
import PortfolioThree from 'sections/portfolio/PortfolioThree.js';
import ContactPersonal from 'sections/contact/ContactPersonal.js';
import ServicesOne from '../sections/services/ServicesOne';
import { Switch } from '@material-ui/core';
import TestimonialsOne from '../sections/testimonials/TestimonialsOne';
import ReactCountryFlag from 'react-country-flag';
import CookieConsent from 'react-cookie-consent';

const Index = props => {
	const { site } = props.data;

	return (
		<div>
			<Helmet>
				<title>{site.meta.title}</title>
				<meta name='description' content={site.meta.description} />
			</Helmet>
			<Layout isHome={true} sections={['home', 'über mich', 'leistungen', 'kontakt']}>
				<HeroPersonal />
				<AboutPersonal />
				<ServicesOne />
				{/* <PortfolioThree />
				<TestimonialsOne /> */}
				<ContactPersonal />
				<CookieConsent
					location='bottom'
					buttonText='Verstanden'
					cookieName='CookieAlexGauss'
					style={{ background: '#111111' }}
					buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
					expires={150}>
					Diese Website verwendet notwendige Cookies.{' '}
					<span style={{ fontSize: '10px' }}>
						Nähere Informationen entnehmen Sie der Datenschutzerklärung
					</span>
				</CookieConsent>
			</Layout>
		</div>
	);
};

export default Index;

// class Index extends React.Component {
// 	render() {
// 		const { site } = this.props.data;

// 		let language = true;

// 		const changeLanguage = () => {
// 			language = !language;
// 		};

// 		console.log(language);

// 		return (
// 			<div>
// 				<Helmet>
// 					<title>{site.meta.title}</title>
// 					<meta name='description' content={site.meta.description} />
// 				</Helmet>
// 				<Layout
// 					isHome={true}
// 					sections={['home', 'über mich', 'leistungen', 'portfolio', 'kontakt']}
// 					language={language}
// 					changeLanguage={changeLanguage}>
// 					<HeroPersonal />
// 					<AboutPersonal />
// 					<ServicesOne />
// 					<PortfolioThree />
// 					<ContactPersonal />
// 					{/* <TestimonialsOne /> */}
// 				</Layout>
// 			</div>
// 		);
// 	}
// }

// export default Index;

export const personalQuery = graphql`
	query {
		site {
			meta: siteMetadata {
				title
				description
			}
		}
	}
`;
